.main{
    opacity: 0;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transition: opacity .5s linear, transform .5s ease-out;
    height: 100dvh;
    overflow: auto;
    padding: 12px 0;
    overflow-x: hidden;
    overflow-y: auto;
}
.main_active {
    opacity: 1;
}
.main__content {
    width: calc(100% - 24px);
    margin: auto;
    max-width: 427px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.91);
    border-radius: 30px;
    box-sizing: border-box;
    padding: 30px 9px 9px 9px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
}
@media screen and (min-width: 768px) {
    .main__content {
        padding: 52px 19px 31px 19px;
        background-color: rgba(255, 255, 255, 0.83);
    }
}
.wrapper {
    width: 100%;
    /* aspect-ratio: 1 / 1; */
    /* height: 348px !important; */
    position: relative;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
    z-index: 11;
}
.main__logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 25px;
}
@media screen and (min-width: 768px) {
    .main__logo {
        width: 270px;
        margin-bottom: 51px;
    }
}
.main__basic {
    box-sizing: border-box;
    padding: 13px 12px 12px 12px;
    position: relative;
    background-color: #FFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    -webkit-flex-shrink: 0;
}
@media screen and (min-width: 768px) {
    .main__basic {
        padding: 19px 19px 23px 19px;
    }
}
.main__topbar {
    margin-bottom: 32px;
}
.topbar {
    display: flex;
    align-items: center;
}
.topbar__item {
    box-sizing: border-box;
    padding: 10px 11px 10px 13px;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 7.61px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4.4px;
    position: relative;
}
@media screen and (max-width: 366px) {
    .topbar__item {
        padding: 7px;
    }
}
@media screen and (min-width: 768px) {
    .topbar__item {
        padding: 12px 13px 12px 15px;
    }
}
.topbar__item-img {
    width: 37px;
    display: block;
}
.topbar__item-img-full {
    display: block;
    width: 37px;
    max-height: 37px;
    position: absolute;
    bottom: 10px;
    object-fit: cover;
    object-position: bottom;
    left: calc(50% + 1px);
    transform: translateX(-50%);
}
@media screen and (max-width: 366px) {
    .topbar__item-img-full {
        bottom: 7px;
        left: 50%;
    }
}
@media screen and (min-width: 768px) {
    .topbar__item-img-full {
        bottom: 12px;
    }
}
.topbar__count {
    box-sizing: border-box;
    padding: 12px 25px 10px 26px;
    background-color: rgba(0, 0, 0, 0.01);
    border: 0.88px solid rgba(0, 0, 0, 0.04);
    border-radius: 7.61px;
    color: #000;
    font-size: 29.94px;
    font-weight: 400;
    line-height: 35.93px;
    text-align: center;
    margin-left: auto;
}
@media screen and (max-width: 387px) {
    .topbar__count {
        padding: 12px 15px 10px 15px;
    }
}
@media screen and (max-width: 366px) {
    .topbar__count {
        padding: 7px 12px 7px 12px;
    }
}
@media screen and (min-width: 768px) {
    .topbar__count {
        font-size: 34px;
        line-height: 40.8px;
        padding: 11px 30px 11px 30px;
    }
}

.main__card-text {
    margin-bottom: 5px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: center;
    min-height: 80px;
}
@media screen and (min-width: 768px) {
    .main__card-text {
        font-size: 21px;
        line-height: 25.2px;
        margin-bottom: 5px;
        min-height: 100px;
    }
}

.topbar__item-circle {
    position: absolute;
    top: 4.4px;
    /* background-color: rgba(255, 96, 0, 1); */
    left: 4.4px;
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    transition: width .24s linear, background-color .24s linear;
    max-width: 15px;
}

