.main2{
    opacity: 0;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transition: opacity .5s linear, transform .5s ease-out;
    height: 100dvh;
    overflow: auto;
    padding: 12px 0;
    overflow-x: hidden;
    overflow-y: auto;
}
.main2_active {
    opacity: 1;
}
.main2__content {
    width: calc(100% - 24px);
    margin: auto;
    max-width: 427px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.91);
    border-radius: 30px;
    box-sizing: border-box;
    padding: 30px 9px 9px 9px;
}
@media screen and (min-width: 768px) {
    .main2__content {
        padding: 52px 19px 31px 19px;
        background-color: rgba(255, 255, 255, 0.83);
    }
}
.wrapper {
    width: 100%;
    aspect-ratio: 1 / 1;
    position: relative;
    flex-shrink: 0;
    z-index: 11;
  }
.main2__logo {
    display: block;
    margin: 0 auto;
    margin-bottom: 25px;
}
@media screen and (min-width: 768px) {
    .main2__logo {
        width: 270px;
        margin-bottom: 51px;
    }
}
.main2__basic {
    box-sizing: border-box;
    padding: 13px 12px 12px 12px;
    position: relative;
    background-color: #FFF;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
}
@media screen and (min-width: 768px) {
    .main2__basic {
        padding: 19px 19px 23px 19px;
    }
}
.main2__topbar {
    margin-bottom: 32px;
}
.topbar2 {
    display: flex;
    align-items: center;
}
.topbar2__item {
    box-sizing: border-box;
    padding: 10px 11px 10px 13px;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 7.61px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 4.4px;
    position: relative;
}
@media screen and (max-width: 366px) {
    .topbar2__item {
        padding: 7px;
    }
}
@media screen and (min-width: 768px) {
    .topbar2__item {
        padding: 12px 13px 12px 15px;
    }
}
.topbar2__item-img {
    width: 37px;
    display: block;
}
.topbar2__item-img-full {
    display: block;
    width: 37px;
    max-height: 37px;
    position: absolute;
    bottom: 10px;
    object-fit: cover;
    object-position: bottom;
    left: calc(50% + 1px);
    transform: translateX(-50%);
}
@media screen and (max-width: 366px) {
    .topbar2__item-img-full {
        bottom: 7px;
        left: 50%;
    }
}
@media screen and (min-width: 768px) {
    .topbar2__item-img-full {
        bottom: 12px;
    }
}
.topbar2__count {
    box-sizing: border-box;
    padding: 12px 25px 10px 26px;
    background-color: rgba(0, 0, 0, 0.01);
    border: 0.88px solid rgba(0, 0, 0, 0.04);
    border-radius: 7.61px;
    color: #000;
    font-size: 29.94px;
    font-weight: 400;
    line-height: 35.93px;
    text-align: center;
    margin-left: auto;
}
@media screen and (max-width: 387px) {
    .topbar2__count {
        padding: 12px 15px 10px 15px;
    }
}
@media screen and (max-width: 366px) {
    .topbar2__count {
        padding: 7px 12px 7px 12px;
    }
}
@media screen and (min-width: 768px) {
    .topbar2__count {
        font-size: 34px;
        line-height: 40.8px;
        padding: 11px 30px 11px 30px;
    }
}
.main2__card-text {
    margin-bottom: 50px;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: center;
}
@media screen and (min-width: 768px) {
    .main2__card-text {
        font-size: 21px;
        line-height: 25.2px;
        margin-bottom: 40px;
    }
}
.topbar2__item-circle {
    position: absolute;
    top: 4.4px;
    /* background-color: rgba(255, 96, 0, 1); */
    left: 4.4px;
    border-radius: 100%;
    aspect-ratio: 1 / 1;
    transition: width .24s linear, background-color .24s linear;
    max-width: 15px;
}

.training {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(4px); /* Add this line first, it fixes blur for Safari*/
    backdrop-filter: blur(4px);
    /* filter: blur(4px);
    transform: translate3d(0, 0, 0); */
    z-index: 10;
    pointer-events: none;
}
.training__arrows {
    position: absolute;
    top: 166px;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;
}
.training__text {
    position: absolute;
    top: 210px;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    max-width: 299px;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: center;
    z-index: 11;
    width: 100%;
}
@media screen and (min-width: 768px) {
    .training__text {
        font-size: 22px;
        line-height: 26.4px;
    }
}
.training__start-text {
    position: absolute;
    top: 400px;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    max-width: 317px;
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: center;
    z-index: 11;
    width: 100%;
}
@media screen and (max-height: 900px) {
    .training__start-text {
        top: 300px;
    }
}
@media screen and (max-height: 800px) {
    .training__start-text {
        top: 261px;
    }
}
@media screen and (min-width: 768px) {
    .training__start-text {
        font-size: 22px;
        line-height: 26.4px;
    }
}
@media screen and (min-height: 1100px) {
    .training__start-text {
        top: 500px;
    }
}
@media screen and (min-height: 1300px) {
    .training__start-text {
        top: 600px;
    }
}
.training__button {
    pointer-events: all;
    opacity: 1;
    position: fixed;
    left: 50%;
    bottom: 37px;
    transform: translateX(-50%);
    z-index: 100;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 27px 7px;
    box-shadow: 0px 4px 70.3px 0px rgba(255, 96, 0, 0.36);
    background-color: rgba(255, 96, 0, 1);
    border-radius: 19px;
    width: calc(100% - 68px);
    transition: opacity .24s linear;
}
@media screen and (min-width: 768px) {
    .training__button {
        max-width: 307px;
    }
}
.training__button:hover {
    cursor: pointer;
}
.training__button-text {
    color: #FFF;
    font-family: "Myriad Pro";
    font-size: 19px;
    font-weight: 400;
    line-height: 22.8px;
    text-align: center;
    text-transform: uppercase;
}

.training__button-help {
    position: absolute;
    z-index: -1;
    top: 4px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FF7A00 0%, #FF6000 100%);
    opacity: .5;
    border-radius: 19px;
}


.training__top-text {
    color: #FFF;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    text-align: center;
    position: absolute;
    z-index: 11;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
}
.training__arrows-block {
    position: absolute;
    z-index: 11;
    top: 196px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    gap: 18px;
}

.training__bottom-text {
    color: #FFF;
    position: absolute;
    z-index: 11;
    left: 50%;
    transform: translateX(-50%);
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    top: 238px;
}
.training__bottom-bold-text {
    font-weight: 700;
}
.training__arrow {
    transition: opacity .24s linear;
}