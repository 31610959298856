@font-face{
    font-family: "Myriad Pro";
    src: url("../fonts/MYRIADPRO-BOLD.woff") format('woff'), url("../fonts/MYRIADPRO-BOLD.otf") format('otf');
    font-weight: 700;
    font-style:normal;
    font-display:swap;
}
@font-face{
    font-family: "Myriad Pro";
    src: url("../fonts/MYRIADPRO-REGULAR.woff") format('woff'), url("../fonts/MYRIADPRO-REGULAR.otf") format('otf');
    font-weight: 400;
    font-style:normal;
    font-display:swap;
}
@font-face{
    font-family: "Myriad Pro";
    src: url("../fonts/MYRIADPRO-SEMIBOLD.woff") format('woff'), url("../fonts/MYRIADPRO-SEMIBOLD.otf") format('otf');
    font-weight: 600;
    font-style:normal;
    font-display:swap;
}
:root{
    --black: #000;
    --white: #FFFFFF;
    --red: #ED1C24;
    --grey: #F1F1F0;
}
/* #root {
    overflow-y: auto;
    overflow-x: hidden;
} */

*{
    outline: none !important;
}

ul,
ol,
p,
div,
label,
h1,h2,h3{
    list-style: none;
    padding: 0;
    margin: 0;
}

button, button span{
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

div {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

img{
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    user-select: none;
}

a,a:hover{
    text-decoration: none;
}

body,html{
    font-family: 'Myriad Pro', sans-serif;
    /* min-height: 100vh; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* overflow-x: hidden; */
    /* background-color: rgba(0, 146, 58, 1); */
    background-color: rgba(255, 96, 0, 1);
}
body {
    /* height: 100vh !important;
    overflow: hidden; */
    /* max-height: 100vh !important; */
}
#root {
    /* overflow-x: hidden;
    overflow-y: auto; */
    /* position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; */
}
.main-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.main-help-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    object-fit: cover;
    object-position: center;
}
.music-icon:hover {
    cursor: pointer;
}
.ios-help-bg {
    position: fixed;
    background-color: rgba(255, 96, 0, 1);
    top: -400px;
    left: 0;
    right: 0;
    bottom: -400px;
    z-index: -10;
}
.not-ios-help {
    display: none;
}
.container {
    scrollbar-width: 0;
    -ms-overflow-style: 0;
}
  
.container::-webkit-scrollbar {
    width: 0px;
    opacity: 0;
}


.modal{
    width: 375px;
    /* min-height: 420px; */
    background-color: #fff;
    border-radius: 20px;
    box-sizing: border-box;
    padding: 40px 32px 36px;
    position: relative;
}

.modal__close{
    display: flex;
    width: 24px;
    height: 24px;
    background-color: var(--grey);
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    right: 12px;
    top: 12px;
}

.container{
    /* min-height: 100vh;
    overflow: auto;
    position: relative; */
}