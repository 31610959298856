
.loading-page{
    opacity: 1;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transition: opacity .5s linear, transform .5s ease-out;
    height: 100dvh;
    overflow: auto;
    padding: 12px 0;
}
.loading-page_disable {
    opacity: 0;
}
.loading-page__content {
    width: calc(100% - 24px);
    margin: auto;
    max-width: 427px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.83);
    border-radius: 30px;
    box-sizing: border-box;
    padding: 30px 9px 9px 9px;
}
@media screen and (min-width: 768px) {
    .loading-page__content {
        padding: 52px 19px 31px 19px;
    }
}
.loading-page__logo {
    margin: 0 auto;
    display: block;
    margin-bottom: 25px;
}
@media screen and (min-width: 768px) {
    .loading-page__logo {
        width: 270px;
        margin-bottom: 51px;
    }
}
.loading-page__main {
    box-sizing: border-box;
    padding: 45px 13px 15px 13px;
    position: relative;
    background-color: #FFF;
    border-radius: 20px;
    overflow: hidden;
}
.loading-page__cloud {
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    object-fit: cover;
    object-position: center;
    pointer-events: none;
}
.loading-page__cloud_left {
    left: 0;
}
.loading-page__cloud_right {
    right: 0;
}
.loading-page__coin {
    position: relative;
    width: 270px;
    z-index: 1;
    display: block;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    /* margin: 0 auto; */
    /* margin-bottom: 50px; */
}
/* @media screen and (max-width: 766px) {
    .loading-page__coin {
        margin-bottom: 70px;
    }
}
@media screen and (max-width: 450px) {
    .loading-page__coin {
        margin-bottom: 45px;
    }
}
@media screen and (max-width: 398px) {
    .loading-page__coin {
        margin-bottom: 20px;
    }
} */
.loading-page__title {
    position: relative;
    z-index: 1;
    color: rgba(255, 96, 0, 1);
    font-size: 24px;
    font-weight: 700;
    line-height: 26.4px;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 270px;
}
@media screen and (min-width: 768px) {
    .loading-page__title {
        font-size: 29px;
        line-height: 34.8px;
        margin-top: 250px;
    }
}
@media screen and (max-width: 420px) {
    .loading-page__title {
        margin-top: 240px;
    }
}
@media screen and (max-width: 385px) {
    .loading-page__title {
        margin-top: 215px;
    }
}
.loading-page__subtitle {
    position: relative;
    z-index: 1;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    margin-bottom: 17px;
}
@media screen and (min-width: 768px) {
    .loading-page__subtitle {
        font-size: 18px;
        line-height: 21.6px;
    }
}
.loading-page__button {
    position: relative;
    z-index: 1;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 27px 7px;
    box-shadow: 0px 4px 70.3px 0px rgba(255, 96, 0, 0.36);
    background-color: rgba(255, 96, 0, 1);
    border-radius: 19px;
    width: 100%;
    transition: transform .24s linear;
}
@media screen and (min-width: 768px) {
    .loading-page__button {
        padding: 24px 7px;
    }
}
.loading-page__button:hover {
    cursor: pointer;
    transform: translateY(-3px);
}
.loading-page__button-text {
    color: #FFF;
    font-family: "Myriad Pro";
    font-size: 19px;
    font-weight: 400;
    line-height: 22.8px;
    text-align: center;
    text-transform: uppercase;
}
@media screen and (min-width: 768px) {
    .loading-page__button-text {
        font-size: 24px;
        line-height: 28.8px;
    }
}
.loading-page__button-help {
    position: absolute;
    z-index: -1;
    top: 4px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FF7A00 0%, #FF6000 100%);
    opacity: .5;
    border-radius: 19px;
}