.onboarding1{
    opacity: 0;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transition: opacity .5s linear, transform .5s ease-out;
    height: 100dvh;
    overflow: auto;
    padding: 12px 0;
}
.onboarding1_active {
    opacity: 1;
}
.onboarding1__content {
    width: calc(100% - 24px);
    margin: auto;
    max-width: 427px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    background-color: #FFF;
    border-radius: 30px;
    box-sizing: border-box;
    padding: 30px 9px 9px 9px;
}
@media screen and (min-width: 768px) {
    .onboarding1__content {
        padding: 52px 19px 31px 19px;
        background-color: rgba(255, 255, 255, 0.83);
    }
}
.onboarding1__logo {
    margin: 0 auto;
    display: block;
    margin-bottom: 25px;
}
@media screen and (min-width: 768px) {
    .onboarding1__logo {
        width: 270px;
        margin-bottom: 51px;
    }
}
.onboarding1__main {
    box-sizing: border-box;
    padding: 21px 13px 26px 13px;
    position: relative;
    background-color: rgba(0, 0, 0, 0.01);
    border-radius: 20px;
    overflow: hidden;
}
@media screen and (min-width: 768px) {
    .onboarding1__main {
        background-color: rgba(255, 255, 255, 0.74);
        padding: 25px 23px 31px 23px;
    }
}
.onboarding1__button {
    pointer-events: none;
    opacity: .5;
    position: relative;
    z-index: 1;
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 27px 7px;
    box-shadow: 0px 4px 70.3px 0px rgba(255, 96, 0, 0.36);
    background-color: rgba(255, 96, 0, 1);
    border-radius: 19px;
    width: 100%;
    transition: opacity .24s linear, transform .24s linear;
}
@media screen and (min-width: 768px) {
    .onboarding1__button {
        padding: 24px 7px;
    }
}
.onboarding1__button_active {
    opacity: 1;
    pointer-events: all;
}
.onboarding1__button_active:hover {
    cursor: pointer;
    transform: translateY(-3px);
}
.onboarding1__button-text {
    color: #FFF;
    font-family: "Myriad Pro";
    font-size: 19px;
    font-weight: 400;
    line-height: 22.8px;
    text-align: center;
    text-transform: uppercase;
}
@media screen and (min-width: 768px) {
    .onboarding1__button-text {
        font-size: 24px;
        line-height: 28.8px;
    }
}
.onboarding1__button-help {
    position: absolute;
    z-index: -1;
    top: 4px;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #FF7A00 0%, #FF6000 100%);
    opacity: .5;
    border-radius: 19px;
}
.onboarding1__title {
    color: rgba(255, 96, 0, 1);
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
    margin-bottom: 17px;
}
.onboarding1__subtitle {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: center;
    margin-bottom: 23px;
}
@media screen and (min-width: 768px) {
    .onboarding1__subtitle {
        margin-bottom: 37px;
    }
}
.onboarding1__label {
    margin-bottom: 9px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
}
.onboarding1__input {
    border: none;
    outline: none;
    box-sizing: border-box;
    padding: 21px 23px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    background-color: rgba(0, 0, 0, 0.04);
    width: 100%;
    border-radius: 10px;
    color: #000;
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
}
.onboarding1__input::placeholder {
    color: rgba(0, 0, 0, .3);
}
.onboarding1__input:not(:last-of-type) {
    margin-bottom: 22px;
}
.onboarding1__input:last-of-type {
    margin-bottom: 16px;
}

.invalid-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity .24s linear;
}
.invalid-popup_active {
    opacity: 1;
    pointer-events: all;
}
.invalid-popup__content {
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    background-color: #FFF;
    border-radius: 30px;
    padding: 40px 17px 16px 15px;
}
.invalid-popup__title {
    color: rgba(255, 96, 0, 1);
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
    margin-bottom: 14px;
}
.invalid-popup__subtitle {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    margin-bottom: 27px;
}
.invalid-popup__button {
    width: 270px;
    box-sizing: border-box;
    padding: 27px 7px;
    border-radius: 19px;
    border: none;
    outline: none;
    background-color: rgba(255, 96, 0, 1);
    color: #FFF;
    text-transform: uppercase;
    font-family: "Myriad Pro";
    font-size: 19px;
    font-weight: 400;
    line-height: 22.8px;
    text-align: center;
}
.invalid-popup__button:hover {
    cursor: pointer;
}

.onboarding1__checkbox {
    display: flex;
    align-items: center;
    gap: 7px;
}
.onboarding1__checkbox:hover {
    cursor: pointer;
}
.onboarding1__checkbox:not(:last-of-type) {
    margin-bottom: 14px;
}
.onboarding1__checkbox:last-of-type {
    margin-bottom: 28px;
}
.onboarding1__checkbox-img {
    display: block;
}
.onboarding1__checkbox-text {
    color: rgba(0, 0, 0, 0.6);
    font-size: 11px;
    font-weight: 400;
    line-height: 13.2px;
    text-align: left;
}
.onboarding1__link {
    color: inherit;
    text-decoration: underline;
}