.final{
    opacity: 0;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    transition: opacity .5s linear, transform .5s ease-out;
    height: 100dvh;
    overflow: auto;
    padding: 12px 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 12px 0;
}
@media screen and (min-width: 768px) {
    .final {
        justify-content: center;
    }
}
.final_active {
    opacity: 1;
}
.final__content {
    width: calc(100% - 24px);
    margin: auto;
    max-width: 427px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.83);
    border-radius: 30px;
    box-sizing: border-box;
    padding: 36px 16px 17px 16px;
    position: relative;
    display: flex;
    flex-direction: column;
}
@media screen and (min-width: 768px) {
    .final__content {
        padding: 185px 16px 17px 16px;
        width: 785px;
        max-width: none;
    }
}
@media screen and (min-width: 768px) and (max-height: 936px) {
    .final__content {
        padding-top: 100px;
    }
}

.final__title {
    color: rgba(255, 96, 0, 1);
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
    margin-bottom: 18px;
    z-index: 1;
}
@media screen and (min-width: 768px) {
    .final__title {
        font-size: 50px;
        line-height: 50px;
        margin-bottom: 25px;
    }
}
.final__subtitle {
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    margin: 0 auto;
    max-width: 299px;
    z-index: 1;
}
@media screen and (min-width: 768px) {
    .final__subtitle {
        max-width: 484px;
        font-size: 20px;
        line-height: 24px;
        color: rgba(0, 0, 0, .6);
    }
}
.final__img {
    width: 100%;
    object-fit: cover;
}
@media screen and (min-width: 768px) {
    .final__img {
        position: absolute;
        width: 352px;
        top: -150px;
        left: 50%;
        transform: translateX(-50%);
    }
}
@media screen and (min-width: 768px) and (max-height: 936px) {
    .final__img {
        width: 250px;
        top: -135px;
    }
}
@media screen and (min-width: 768px) and (max-height: 858px) {
    .final__img {
        width: 200px;
        top: -95px;
    }
}
@media screen and (min-width: 768px) and (max-height: 783px) {
    .final__img {
        width: 150px;
        top: -45px;
    }
}
.final__info {
    box-sizing: border-box;
    width: 100%;
    padding: 8px;
    border-radius: 66px;
    background-color: rgba(255, 255, 255, 0.4);
    color: rgba(0, 0, 0, .6);
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: center;
    margin-bottom: 8px;
    z-index: 1;
}
@media screen and (min-width: 768px) {
    .final__info {
        margin-bottom: 16px;
    }
}
.final__buttons {
    margin-top: auto;
}
.final__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    box-sizing: border-box;
    padding: 22px 7px;
    background-color: transparent;
    border: 1px solid rgba(255, 96, 0, 1);
    border-radius: 93px;
    width: 100%;
    transition: transform .24s linear;
    margin-bottom: 8px;
}
.final__button:hover {
    cursor: pointer;
    transform: translateY(-3px);
}
.final__button-text {
    color: rgba(255, 96, 0, 1);
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
}
.final__info-btn {
    border: none;
    outline: none;
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 26px 7px;
    border-radius: 93px;
    background-color: rgba(255, 96, 0, 1);
    position: relative;
    color: #FFF;
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    margin-bottom: 8px;
    transition: transform .24s linear;
}
.final__info-btn:hover {
    cursor: pointer;
    transform: translateY(-3px);
}
.final__info-btn-link {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.final__sticker {
    box-sizing: border-box;
    padding: 26px 7px;
    text-decoration: none;
    display: block;
    color: rgba(255, 96, 0, 1);
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    border: 1px solid rgba(255, 96, 0, 1);
    border-radius: 93px;
    transition: transform .24s linear;
}
.final__sticker:hover {
    transform: translateY(-3px);
}