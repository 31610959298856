.reputation{
    opacity: 0;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: opacity .5s linear, transform .5s ease-out;
    /* height: 100dvh; */
    height: 100vh;
    overflow: auto;
    padding: 12px 0;
}
@media screen and (min-width: 768px) {
    .reputation {
        justify-content: center;
    }
}
@media screen and (min-width: 768px) and (max-height: 800px) {
    .reputation {
        justify-content: flex-end;
    }
}
.reputation_active {
    opacity: 1;
}
.reputation__content {
    width: calc(100% - 24px);
    margin: 0 auto;
    max-width: 427px;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.05);
    background-color: rgba(255, 255, 255, 0.83);
    border-radius: 30px;
    box-sizing: border-box;
    padding: 185px 16px 17px 16px;
    position: relative;
}
@media screen and (max-height: 660px) and (max-width: 767px) {
    .reputation__content {
        padding-top: 100px;
    }
}
@media screen and (min-width: 768px) {
    .reputation__content {
        padding: 185px 16px 17px 16px;
        width: 785px;
        max-width: none;
    }
}
@media screen and (min-width: 768px) and (max-height: 900px) {
    .reputation__content {
        padding-top: 100px;
    }
}
.reputation__img {
    display: block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -100%);
    top: 200px;
    pointer-events: none;
    width: 315px;
}
@media screen and (max-height: 660px) and (max-width: 767px) {
    .reputation__img {
        top: 156px;
    }
}
@media screen and (max-height: 615px) and (max-width: 767px) {
    .reputation__img {
        width: 260px;
        top: 156px;
    }
}
@media screen and (max-height: 567px) and (max-width: 767px) {
    .reputation__img {
        width: 230px;
        top: 156px;
    }
}
@media screen and (min-width: 768px) {
    .reputation__img {
        width: 500px;
        top: 260px;
    }
}
@media screen and (min-width: 768px) and (max-height: 900px) {
    .reputation__img {
        top: 184px;
        width: 400px;
    }
}
@media screen and (min-width: 768px) and (max-height: 700px) {
    .reputation__img {
        top: 164px;
        width: 300px;
    }
}
.reputation__title {
    color: rgba(255, 96, 0, 1);
    font-size: 28px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
    margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
    .reputation__title {
        font-size: 50px;
        line-height: 50px;
    }
}
.reputation__subtitle {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 20px;
    max-width: 299px;
}
@media screen and (max-height: 540px) and (max-width: 767px) {
    .reputation__subtitle {
        margin-bottom: 15px;
    }
}
@media screen and (min-width: 768px) {
    .reputation__subtitle {
        color: rgba(0, 0, 0, .6);
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 40px;
        max-width: 484px;
    }
}
.reputation__info {
    box-sizing: border-box;
    width: 100%;
    padding: 11px 29px;
    border-radius: 66px;
    background-color: rgba(255, 255, 255, 0.4);
    color: rgba(0, 0, 0, .6);
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    text-align: center;
    margin-bottom: 15px;
}
@media screen and (min-width: 768px) {
    .reputation__info {
        padding: 15px 28px;
    }
}
.reputation__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    box-sizing: border-box;
    padding: 22px 7px;
    background-color: transparent;
    border: 1px solid rgba(255, 96, 0, 1);
    border-radius: 93px;
    width: 100%;
    transition: transform .24s linear;
    margin-bottom: 8px;
}
.reputation__button:hover {
    cursor: pointer;
    transform: translateY(-3px);
}
.reputation__button-text {
    color: rgba(255, 96, 0, 1);
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: left;
}

.reputation__info-btn {
    border: none;
    outline: none;
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 26px 7px;
    border-radius: 93px;
    background-color: rgba(255, 96, 0, 1);
    position: relative;
    color: #FFF;
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    margin-bottom: 8px;
    transition: transform .24s linear;
}
.reputation__info-btn:hover {
    cursor: pointer;
    transform: translateY(-3px);
}
.reputation__info-btn-link {
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.reputation__sticker {
    box-sizing: border-box;
    padding: 26px 7px;
    text-decoration: none;
    display: block;
    color: rgba(255, 96, 0, 1);
    font-family: "Myriad Pro";
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    text-align: center;
    border: 1px solid rgba(255, 96, 0, 1);
    border-radius: 93px;
    transition: transform .24s linear;
}
.reputation__sticker:hover {
    transform: translateY(-3px);
}